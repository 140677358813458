import ApiSubscribeUsers from '@/api/subscribe-users'

export default {
	name: 'Form',
	data: () => ({
        errors: {},
        isFormSent: false,
        valid: false,
        form: {
            email: '',
            type: ''
        },
        sending: false
    }),
    created() {
        this.form.type =  this.$route.query.type;
    },
	computed: {
		emailRules () {
            const emailFilter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
            
            return [
                v => !!v || this.cleanFromHtmlTags(this.trans('under_constraction_form_email_is_required')),
                v => emailFilter.test(v) ||
                    this.cleanFromHtmlTags(this.trans('under_constraction_form_email_must_valid')),
                v => !(/[а-яА-ЯЁё]/.test(v)) || 
                    this.cleanFromHtmlTags(this.trans('under_constraction_form_email_must_not_cyrillic'))
            ]
        },
	},
	methods: {
        cleanFromHtmlTags: text => text.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ''),
		submit () {
			if (!this.$refs.form.validate()) return
			this.sending = true
			this.addEmails()
			ApiSubscribeUsers.create(this).then(() => {
				this.sending = false
				this.isFormSent = true
			}).catch(err => {
                this.sending = false 
				this.showErrors(err.response.data.message)
			})
		},
		addEmails () {
			if (!this.state.emails && !this.state.emails.length) return
			let email = this.state.emails.find(item => item.key === 'under_construction')
			this.form.from = email.email_from
			this.form.admin_email = email.email_to
		},
		showErrors (errors) {
			this.errors = errors
		},
		clearError (field) {
			if (this.errors) this.errors[field] = ''
		}
	}
}
