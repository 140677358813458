import UnderConstructionImage from "@/components/under-construction/UnderConstructionImage";
import Form from "@/components/under-construction/form/Form.vue";

export default {
	name: "Subheader",
	components: {
		UnderConstructionImage,
		Form,
	},
};
